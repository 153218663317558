import styled from 'styled-components'
import { Box, HeadingStyled, Span, SpanStyled, TextStyled } from './Elements';


export const sliceProps = (props, sub) => {
    const { children, ...otherProps } = props;
    sub.forEach(prop => delete otherProps[prop]);
    return otherProps;
}

export const ButtonWithIcon = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ButtonWrapper {...sliceProps(props, unwantedProps)} className='d-flex align-items-center'>
            <button {...sliceProps(props, unwantedProps)} className={`header-btn ${!props.icon ? 'Btn-icon' : ''} `} autoComplete='false'>
                <SpanStyled color='var(--WhiteColor)' size='14px'>{props.label ? props.label : ''}</SpanStyled>
                {props.icon && (
                    <props.icon width='20px' />
                )}
            </button>
        </ButtonWrapper>
    )
}

export const ContactButton = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ContactWrapper {...sliceProps(props, unwantedProps)}>
            <button {...sliceProps(props, unwantedProps)} className={`click-btn btn-style501 ${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                {props.icon && (
                    <props.icon width='22px' />
                )}
                <HeadingStyled color='var(--WhiteColor)' transform='math-auto' size='14px' weight='normal'>{props.label ? props.label : ''}</HeadingStyled>
            </button>
        </ContactWrapper>
    )
}
export const InputWithIcon = (props) => {
    const unwantedProps = ['icon', "label"];
    return (
        <InputStyledWrap className='position-relative' >
            <InputStyled {...sliceProps(props, unwantedProps)} className={`w-100 ${!props.icon ? 'no-icon' : ''}`} autoComplete='false' />
            {props.icon && (
                <props.icon width='18px' className='position-absolute input-svg' />
            )}
        </InputStyledWrap>
    )
}
export const TextWithIcon = (props) => (
    <TextWithStyledWrapper color={props.color} className={`d-flex ${props.desc ? 'align-items-start' : 'align-items-center my-2'}`}>
        <Box className='mx-2 d-flex align-items-center justify-content-center svg'>
            <props.icon width='20px' height='20px' />
        </Box>
        <Box>
            <Box className='mb-0 contact-text' size={props.headingSize ? props.headingSize : '14px'}
                color={!props.alsotextColor ? 'var(--gray)' : 'var(--white)'}
                dangerouslySetInnerHTML={{ __html: props.title }}>
            </Box>
            {props.desc ?
                <TextStyled className='text-uppercase' color={'var(--gray)'} size='14px' >
                    {props?.desc}
                </TextStyled>
                : null}
        </Box>
    </TextWithStyledWrapper>
)

const TextWithStyledWrapper = styled.div`
  svg {
      [stroke] {
          stroke: ${({ color }) => color ? color : ''};
      }
  }
`
export const InputStyled = styled.input`
    background:var(--bgColor);
    border: none;
    border-radius: 8px;
    height: 43px;
    // color: var(--textGrey);
    &:not(.no-icon) {
        padding-left: 50px;
    }
    &.no-icon {
        padding-left: 18px;
    }
    padding-right: 18px;
    outline: none;
    &,&::placeholder {
        font-size: 14px;
        color: var(--textPalceholder);
    }
`
export const InputStyledWrap = styled.div`
    border-radius: 8px;
    background: var(--white);
    svg {
    left: 13px;
    top: 12px;
    height: 20px;
    }
`


export const ContactWrapper = styled.span`
button{
    color:var(--white);
    background-color: ${props => props.bg ? props.bg : 'var(--yellow)'};
    border: none;
    padding: 4px 22px;
    border-radius: 10px;
    transition: all .4s ease-in-out;
    &:hover{
       opacity:0.8;
    }
}
    
    
`
export const ButtonWrapper = styled.div`
button{
    color:var(--white);
    background-color: ${props => props.bg ? props.bg : 'var(--yellow)'};
    border: none;
    padding: 6px 22px;
    border-radius: 8px;
    transition: all .4s ease-in-out;
    &:hover{
       opacity:0.8;
    }
}

  
`