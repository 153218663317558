import styled from 'styled-components'

export const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    background: var(--white);
    padding:5px 0px;
    transition: all 0.5s cubic-bezier(.77,0,.18,1);
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        display:flex;  
        align-items: center;
        gap: 20px;
    }
    .nav-bar-list a{
        color:var(--black);
        padding:8px 15px;
        position: relative;
        font-size:16px;
        font-family: var(--semibold);
        text-transform: math-auto;
        border-radius: 10px;
        &:hover, &.active, &:focus{
            color:var(--white);
            background: var(--themeColor);
            font-family: var(--semibold);
        }
    }
    .logo{
        cursor:pointer;
        svg{
            width:230px;
        }
    }
    &.sticky{
        background: var(--white);
        box-shadow: 0px 4px 10px #626262ad;
        padding:7px 0px;
        z-index: 200;
    }
    .toggleBtn{
        display: none;
    }
}
`