import React, { useState } from 'react'
import { ContactFormWrapper } from './styled'
import { useForm } from "react-hook-form"
import { Box, LabelStyled } from '../../Styled/Elements'
import { ButtonWithIcon } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BtnArrow, Email, Message, Phone, User } from '../../Styled/AllImages'
const ContactForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
            language: '',
        }
    })
    const getApi = async (data) => {
        try {
            setIsLoading(true);
            const response = await axios.post("https://prmintro.app4orce.com/api/public/api/v1/contact-us-info", data);
            if (response.status === 200) {
                reset({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    language: '',
                });
                // toast.success("Your submission was successful.", { autoClose: 3000 });
                toast.success(t('submission'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    // className: 'message'
                })
                setIsLoading(false);
            } else {
                toast.error(t('submission_fail'));
            }
        } catch (error) {
            console.error("Error submitting the form:", error);
            alert("Submission has failed.");
        }
    }
    const [phone, setPhone] = useState('');
    const handlePhoneChange = (e) => {
        // Allow only positive numbers
        const input = e.target.value;
        if (!isNaN(input) && input >= 0) {
            setPhone(input);
        }
    };

    const onSubmit = async (data) => {
        getApi(data)
        console.log(data);
    };

    return (
        <ContactFormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box className='field-box'>
                    <LabelStyled>{t('name')}</LabelStyled>
                    <Box>
                        <User className='input-svg' />
                        <input className='input-field' placeholder={t('namePlaceholder')}  {...register("name", { required: true })} aria-invalid={errors.name ? "true" : "false"} />
                        {errors.name?.type === "required" && (<p className='error-message' role="alert">{t('name_error')}</p>)}
                    </Box>

                </Box>
                <Box className='field-box'>
                    <LabelStyled>{t('email')}</LabelStyled>
                    <Box>
                        <Email className='input-svg' />
                        <input className='input-field' placeholder={t('emailPlaceholder')} type='email'{...register("email", { required: true })} aria-invalid={errors.email ? "true" : "false"} />
                        {errors.email && <p className='error-message' role="alert">{t('email_error')}</p>}
                    </Box>
                </Box>
                <Box className='field-box'>
                    <LabelStyled>{t('contactPhone')}</LabelStyled>
                    <Box>
                        <Phone className='input-svg' />
                        <input className='input-field' placeholder={t('phonePlaceholder')} type='text' value={phone} {...register("phone", { required: true })} aria-invalid={errors.phone ? "true" : "false"} onChange={handlePhoneChange} />
                        {errors.phone && <p className='error-message' role="alert">{t('phone_error')}</p>}
                    </Box>
                </Box>
                <Box className='field-box'>
                    <LabelStyled>{t('yourMessage')}</LabelStyled>
                    <Box>
                        <Message className='input-svg' />
                        <textarea className='input-field' placeholder={t('messagePlaceholder')} cols="30" rows="5" {...register("message", { required: true })} aria-invalid={errors.message ? "true" : "false"} />
                        {errors.message && <p className='error-message' role="alert">{t('message_error')}</p>}
                    </Box>
                </Box>
                <Box className='d-flex justify-content-end my-3'>
                    {isLoading ? <p className='loading-btn'><span className='spinner'></span></p> : null}
                    <ButtonWithIcon type="submit" icon={BtnArrow} label={t('send')} />
                    {/* <p className='loading-btn'><span className='spinner'></span></p> */}
                </Box>

            </form>
            <ToastContainer />
        </ ContactFormWrapper >
    )
}

export default ContactForm