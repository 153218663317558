
import React, { useEffect } from 'react'
import { Box } from './components/Styled/Elements';
import { privateRoute } from './route/PrivateRoute';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, behaviour: 'smooth' })
  }, [location]);

  return (
    <>
      <Box className='main-artical'>
        <Header />
        <Routes>
          {privateRoute.map((list, key) => (
            <Route to={key} {...list} />
          ))}
        </Routes>
        <Footer />
      </Box>
    </>
  );
}

export default App;
