import styled from "styled-components";

export const AllSolutionWrapper = styled.section`
margin-top:100px;
padding-bottom:100px;
background-color:#F9F9F9;
.item-container{
    background: var(--white);
    border-radius: 8px;
    padding: 7px 13px;
    margin:12px 0;
}
.item-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &>span> svg{
        [stroke]{
            stroke:var(--themeColor);
        }
    }
    
}
.mobile-view{
    display:none ;
}
.active-item-box{
    background: var(--themeColor);
    & .border-bottom-line{
        border-bottom:1px solid #149F72;
    }
    & > .item-box{
        color:var(--white);
        position;relative;
        &>span> svg{
            [stroke]{
                stroke:var(--yellow);
            }
        }
    }
    & .report{
        [fill]{
            fill:var(--yellow);
        }
    }
}
.video-react-video{
    width: 100%;
    height: 100% !important;
}
.video-react-controls-enabled{
    height: 79.6%;
    padding-top:0  !important;
}
.video-react-button{
    display:none;
}

`