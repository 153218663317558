import { LazyImage } from "../Styled/Elements"
import { AllUser, Communicate, DashBoard, Far, Inventory, Item, Offer, Order, Purchase, Report, Video1, Video2 } from "../Styled/AllImages"
import { ControlBar, PlayToggle, Player } from "video-react"


export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'feature',
        label: 'Features',
    },
    {
        to: 'howItWork',
        label: 'howItWork',
    },
    {
        to: 'contact',
        label: 'contactUs',
    },
]
export const AllSolution = [
    {
        icon: <Inventory />,
        heading: 'Inventory',
        text: 'Streamline',
        video: <Player autoPlay poster="/assets/poster.png" src={Video1}>
            <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
            </ControlBar>
        </Player>,
    },
    {
        icon: <Purchase />,
        heading: 'Purchase_Request',
        text: 'Streamline',
        video: <Player autoPlay poster="/assets/poster.png" src={Video1}>
            <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
            </ControlBar>
        </Player>,
    },
    {
        icon: <Item />,
        heading: 'Item_Request',
        text: 'Streamline',
        video: <Player autoPlay poster="/assets/poster.png" src={Video1}>
            <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
            </ControlBar>
        </Player>,
    },
    {
        icon: <Order />,
        heading: 'Direct_Purchase_Request',
        text: 'Streamline',
        video: <Player autoPlay poster="/assets/poster.png" src={Video1}>
            <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
            </ControlBar>
        </Player>,
    },
    {
        icon: <Offer />,
        heading: 'Offer_Analysis',
        text: 'Streamline',
        video: <Player autoPlay poster="/assets/poster.png" src={Video1}>
            <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
            </ControlBar>
        </Player>,
    },
    {
        icon: <Far className='report' />,
        heading: 'FAR',
        text: 'Streamline',
        video: <Player autoPlay poster="/assets/poster.png" src={Video1}>
            <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
            </ControlBar>
        </Player>,
    },
    {
        icon: <Report className='report' />,
        heading: 'Reports',
        text: 'Streamline',
        video: <Player autoPlay poster="/assets/poster.png" src={Video1}>
            <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
            </ControlBar>
        </Player>,
    },
    {
        icon: <Communicate />,
        heading: 'Communication',
        text: 'Streamline',
        video: <Player autoPlay poster="/assets/poster.png" src={Video1}>
            <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
            </ControlBar>
        </Player>,
    },
    {
        icon: <AllUser />,
        heading: 'Users',
        text: 'Streamline',
        video: <Player autoPlay poster="/assets/poster.png" src={Video1}>
            <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
            </ControlBar>
        </Player>,
    },
]