import React from 'react'
import { FooterWapper } from './Styled'
import { Container, Row } from 'react-bootstrap'
import { Box, IconBoxColor, SpanStyled } from '../Styled/Elements'
import { Facebook, FooterArabicImg, FooterFaceBook, FooterImg, FooterInsta, FooterLogo, FooterSnap, FooterTwitter, Instagram, Logo, Snapchat, Twitter } from '../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import ChangeButton from '../../Languages/ChangeButton'

const Footer = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';
    const FowlWeb = () => {
        window.open('https://websites.fowl.sa/', '_blank');
    };
    const date_year = new Date();
    return (
        <FooterWapper className='container-fluid mt-4 mb-2'>
            <Container>
                <Row>
                    <Box className='d-flex align-items-center justify-content-between gap-2 footer-content'>
                        <Box className='footer-logo'>
                            <Logo />
                        </Box>
                        <Box className='d-flex flex-column justify-content-center gap-3'>
                            <Box className='footer-revised'>
                                <SpanStyled className='text-center' size='13px' color='var(--gray)' transform='capitalize'> &copy; {date_year.getFullYear()} PRM.  {t('All_Right_Reserved')}  </SpanStyled>
                                {/* <SpanStyled className='text-center' size='13px' color='var(--gray)' transform='capitalize'>{t('Developed_by')}
                                    <SpanStyled className='fowl' color='var(--themeColor)' size='14px' weight='bold' onClick={FowlWeb}> {t('Fowl')}</SpanStyled>
                                </SpanStyled> */}
                            </Box>
                        </Box>
                        <Box className='d-flex gap-3 my-3'>
                            <FooterFaceBook />
                            <FooterInsta />
                            <FooterSnap />
                            <FooterTwitter />
                        </Box>
                        {/* <FooterImg /> */}
                        {/* {isRTL ? <FooterArabicImg /> : < FooterImg />} */}
                    </Box>
                </Row>
            </Container>
            <ChangeButton />
        </FooterWapper>
    )
}

export default Footer