import styled from "styled-components";

export const FeatureWrapper = styled.section`

.service-img-end{
    text-align:end;

}
.service-img-start{
    text-align:start;
}
.order-1{
    order:1;
}
.order-2{
    order:2;
}
.border-bottom-line{
    border-bottom: 1px solid var(--bg);
}
.feature-row{
    position:relative;
    height:320px;
}
.feature-box{
    display: flex;
    justify-content: space-between;
    padding: 50px 50px 30px;
    &> div{
        width:33%;
    }
}
.feature-img{
    width: fit-content;
    position: absolute;
    margin: auto;
    left: 0;
    right: 70px;
    bottom: -50px;
}
`