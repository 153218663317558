import styled from "styled-components";

export const ContactWrapper = styled.section`
overflow: hidden;
.contact-row{
    position:relative;
    padding: 70px 70px 0;
}
.contact-wrap{
    display: flex;
    flex-direction: column; 
}
.contact-wrap .contact-text{
    color:var(--white);
    // margin:15px;
    font-size:16px;
    font-weight:var(--semibold);
}
.contact-img{
    position: absolute;
    width: 260px;
    bottom: 0;
    left: 0;
    right: 120px;
    margin: auto;
}
`

export const ContactFormWrapper = styled.div`
.field-box{
    &>div{
        position:relative;
        margin:8px 0;
    }
}
.input-field{
    border: none;
    border-radius: 8px;
    padding: 6px 0 6px  45px ;
    width: 100%;
}
.input-svg{
    position: absolute;
    top: 10px;
    bottom: 0;
    margin: 0 15px;
    
}
.error-message{
    font-family:var(--arRegular);
    border:1px solid red;
    color:red;
    margin:15px 0 0 ;
    padding:6px 8px;
    border-radius:8px;
    // background-color: var(--white);
}
.loading-btn{
    position: fixed;
    left: 0;
    right: 0;
    // width: 50%;
    // height: 20%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--whiteColor);
    font-family:var(--regular);

}

`