import styled from "styled-components";

export const FooterWapper = styled.footer`
.footer-list{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    & a{
        font-family: var(--semibold);
        &:hover{
            background: var(--DarkText);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
.footer-logo > svg{
    width: 230px;
}
.fowl{
    cursor: pointer;
}
`