import About from "../components/sections/about/About"
import Banner from "../components/sections/banner/Banner"
import Features from "../components/sections/feature/Features"
import Contact from "../components/sections/contact/Contact"
import AllInOne from "../components/sections/allinone/AllInOne"


const Index = () => {
    return (
        <>
            <Banner />
            <About />
            <Features />
            <AllInOne />
            <Contact />
        </>
    )
}

export default Index