import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import styled from 'styled-components'
import { ButtonWithIcon } from '../../Styled/CustomElements'
import { BannerDashboard, BannerItem1, BannerItem2, BtnArrow } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'

const Banner = () => {
    const { t } = useTranslation();
    return (
        <BannerWrapper className='banner-bg' id='home'>
            <Container>
                <Row className='banner-wrap'>
                    <Col lg={4} md={12} className=''>
                        <Box className='banner-content'>
                            <MainHeading className='main-heading' color='var(--white)' weight='var(--medium)'>{t('Process_for')}</MainHeading>
                            <MainHeading className='main-heading' color='var(--yellow)'>{t('Seamless_Procurement')}</MainHeading>
                            <TextStyled color='var(--white)'>
                                {t('Simplify_procurement')}
                            </TextStyled>
                            <ButtonWithIcon icon={BtnArrow} label={t('today_try')} />
                            <SpanStyled className='py-0' weight='var(--regular)' size='14px' color='var(--lightText)'>
                                {t('No_Card_Required')}
                            </SpanStyled>
                        </Box>
                    </Col>
                    <Col lg={8} md={12}>
                        <Box className='banner-dashboard'>
                            <LazyImage className='banner-items-1' src={BannerItem1} />
                            <LazyImage className='banner-dash-img' src={BannerDashboard} />
                            <LazyImage className='banner-items-2 ' src={BannerItem2} />
                        </Box>
                    </Col>
                </Row>
            </Container>
        </BannerWrapper>
    )
}

export default Banner
const BannerWrapper = styled.section`
.banner-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    margin-top: 90px;
}
.banner-content{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 85%;
}
.banner-dashboard{
    position: relative;
    text-align: end;
}
.banner-items-1{
    position: absolute;
    top: -30px;
    right: 0;
    left: 110px;
    margin: auto;
}
}
.banner-items-2{
    position: absolute;
    top: 100px;
    bottom: 0;
    left: -60px;
    margin: auto;
}

`